.arcus-error-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    @include typ-default();
    color: $text-default;
  }

  &__heading {
    display: flex;
    align-items: center;
    flex-direction: column;
    h1 {
      @include typ-h1();
      color: $primary;
      margin-bottom: 16px;
      font-size: 56px;
    }
  }

  &__error {
    margin-top: 32px;

    &--content {
      margin-top: 16px;
    }

    h2 {
      @include typ-h2();
      color: $text-default;
    }
    p{
        margin-top: 8px;
    }
  }

  &__footer {
    margin-top: 32px;
    display: flex;
    
  }
}
