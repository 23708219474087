.arcus-textarea {
//   display: flex;
//   flex-direction: column;
display: block;
position: relative;
  width: 100%;

  label {
    @include typ-default-bold();
    color: $header;
    margin-bottom: 8px;
  }

  textarea {
    width: 100%;
    border: $border solid 1.5px;
    background-color: $white;
    border-radius: 4px;
    padding: 5px;
    outline: none;
    @include typ-default();
    color: $text-default;
  }

  // Sizes
  &__small {
    max-width: 300px;
  }

  &__medium {
    max-width: 750px;
  }

  &__large {
    max-width: 100%;
  }
}
