.arcus-status {
  padding: 2px 6px;
  border-radius: 4px;
  border: 1px solid transparent;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 4px;

  .arcus-icon svg {
    width: 12px;
    height: 12px;
  }

  &__value {
    @include typ-status-text();
  }

  // Colours
  &--grey {
    background-color: $background-grey;
    color: $text-secondary;
    border-color: $border-grey;

    svg {
      fill: $text-secondary;
    }
  }

  &--green {
    background-color: $background-green;
    color: $text-green;
    border-color: $border-green;

    svg {
      fill: $text-green;
    }
  }

  &--red {
    background-color: $background-red;
    color: $text-red;
    border-color: $border-red;

    svg {
      fill: $text-red;
    }
  }

  &--orange{
    background-color: $background-orange;
    color: $text-orange;
    border-color: $border-orange;

    svg {
      fill: $text-orange;
    }
  }

  &--blue {
    background-color: $background-blue;
    color: $text-blue;
    border-color: $border-blue;

    svg {
      fill: $text-blue;
    }
  }
}
