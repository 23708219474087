.arcus-badge {
  // Badge Style
  padding: 1px 6px;
  border-radius: 4px;
  width: fit-content;

  // Default Colours
  background-color: $background-grey;
  color: $text-secondary;
  border: 1px solid $border-grey;

  // Content Formatting
  display: flex;
  align-items: center;

  // Child Items
  &__text {
    font-family: "Status Text";
    padding: 0 4px;

    &--center{
      text-align:center;
      width:100%
    }
  }

  &__icon {
    width: 12px;
    height: 16px;
    // Default Colour
    svg {
      // make the icon text-secondary
      fill: $text-secondary;
    }
  }
}

.arcus-badge-clickable{
    cursor: pointer;
}

.arcus-badge-icon-message{
    position: absolute;
    margin-top: 12px;
    margin-left: 12px;
    background-color: $white;
    border: $border solid 1px;
    border-radius: 4px;
    padding: 6px 4px;
    @include dropShadow;

    span{
        @include typ-small;
    }
}

// Colours
.arcus-bagde-colour {
  // Red
  &__red {
    background-color: $background-red;
    color: $text-red;
    border: 1px solid $border-red;

    // Icon Colour
    svg {
      fill: $text-red;
    }
  }

  // Orange
  &__orange {
    background-color: $background-orange;
    color: $text-orange;
    border: 1px solid $border-orange;
    // Icon Colour
    svg {
      fill: $text-orange;
    }
  }

  // Green
  &__green {
    background-color: $background-green;
    color: $text-green;
    border: 1px solid $border-green;
    // Icon Colour
    svg {
      fill: $text-green;
    }
  }

  // Blue
  &__blue {
    background-color: $background-blue;
    color: $text-blue;
    border: 1px solid $border-blue;
    // Icon Colour
    svg {
      fill: $text-blue;
    }
  }

  // Grey
  &__grey {
    background-color: $background-grey;
    color: $text-secondary;
    border: 1px solid $border-grey;

    // Icon Colour
    svg {
      fill: $text-secondary;
    }
  }
}
