// H1
@font-face {
    font-family: 'H1';
    src: url("../../assets/fonts/SF-Pro-Text-Semibold.TTF");
}

@mixin typ-title{
    font-family: 'H1';
    font-size: 64px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.34px;
}

@mixin typ-h1 {
    font-family: 'H1';
    font-size: 32px;
    font-weight: 600;
}

.text-h1 {
    @include typ-h1;
}

// H2
@font-face {
    font-family: 'H2';
    src: url("../../assets/fonts/SF-Pro-Display-Bold.OTF");
}

@mixin typ-h2 {
    font-family: 'H2';
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.34px;
}

.text-h2 {
    @include typ-h2;
}

// H3
@font-face {
    font-family: 'H3';
    src: url("../../assets/fonts/SF-Pro-Display-Bold.OTF");
}

@mixin typ-h3 {
    font-family: 'H3';
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.34px;
}

.text-h3 {
    @include typ-h3;
}


// Button
@font-face {
    font-family: "Button";
    src: url("../../assets/fonts/SF-Pro-Text-Medium.TTF");
}

@mixin typ-button {
    font-family: "Button";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.15px;
}

.text-button {
    @include typ-button;
}

// Default Bold
@font-face {
    font-family: "Default Bold";
    src: url("../../assets/fonts/SF-Pro-Text-Semibold.TTF");
}

@mixin typ-default-bold {
    font-family: "Default Bold";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.15px;
    text-decoration: none ;
}

.text-default-bold {
    @include typ-default-bold;
}

// Default
@font-face {
    font-family: "Normal";
    src: url("../../assets/fonts/SF-Pro-Text-Regular.TTF");
}

@mixin typ-default {
    font-family: "Normal";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
}

.text-default {
    @include typ-default;
}

// Small
@font-face {
    font-family: 'Small';
    src: url("../../assets/fonts/SF-Pro-Text-Regular.TTF");
}

@mixin typ-small {
    font-family: 'Small';
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
}

.text-small {
    @include typ-small;
}

// Tiny
@mixin typ-tiny{
    font-family: 'Small';
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
}

.text-tiny {
    @include typ-tiny;
}

// Table
@font-face {
    font-family: "Table";
    src: url("../../assets/fonts/SF-Pro-Text-Medium.TTF");
}

@mixin typ-table {
    font-family: "Table";
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.34px;
}

.text-table {
    @include typ-table;
}

// Robot Text
@font-face {
    font-family: 'Robot Text';
    src: url("../../assets/fonts/Menlo-Regular.TTF");
}

@mixin typ-robot-text {
    font-family: 'Robot Text';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.text-robot-text {
    @include typ-robot-text;
}


// Status Text
@font-face {
    font-family: 'Status Text';
    src: url("../../assets/fonts/SF-Pro-Text-Semibold.TTF");
}

@mixin typ-status-text {
    font-family: 'Status Text';
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.2px;
}

.text-status-text {
    @include typ-status-text;
}

pre{
    font-family: 'Robot Text';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}