.arcus-card {
  background-color: $white;
  border: $border solid 1px;
  border-radius: 4px;
  padding: 16px;
  width: fit-content;

  &__header {
    display: flex;
    align-items: center;

    &--icon {
      svg {
        width: 16px;
        height: 16px;
        fill: $text-secondary;
      }
      margin-right: 8px;
    }

    &--title {
      @include typ-default-bold();
      color: $header;
      margin-bottom: 0;
    }
  }

  &__body {
    &--icon {
      padding-left: 24px;
    }
    &--large-value {
      @include typ-default-bold();
    }
  }

  // Sizes
  &--small {
    width: 288px;
  }

  &--large {
    width: 100%;
  }

  &--JSON {
    max-width: 650px;
    width: 100%;
    margin-top: 32px;
    .arcus-card__heading {
      margin-bottom: 18px;
    }

    pre {
      color: $text-default;
      background-color: #f6f6f6;
      border-radius: 4px;
      padding: 16px;
      margin: 0;
      overflow: auto;
      white-space: pre-wrap;
      word-wrap: break-word;
      max-height: 500px;
      overflow-y: auto;
    }
  }

  &--InfoCard {
    min-width: 250px;
    min-height: 63px;
    @include dropShadow();
    padding: 6px 8px;

    .arcus-card__header {
      display: flex;
      align-items: flex-start;

      &--title {
        @include typ-default-bold();
        color: $text-secondary;
        margin-bottom: 0;
        margin-right: 8px;
      }
    }

    .arcus-card__body {
      &--large-value {
        @include typ-h3();
        color: $header;
      }
    }
  }

  // Backgrounds
  &--secondary {
    background-color: $background-card;

    border: none;
  }

  &--colour-negative {
    background-color: $background-orange;
    * {
      color: $text-orange !important;
    }
  }
}
