.arcus-alert {
  position: absolute;
  bottom: 25px;
  right: 50px;
  z-index: 99999;
  background-color: $white;
  border: $border solid 1.5px;
  border-radius: 4px;
  display: none;

  min-width: 300px;
  width: fit-content;
  padding: 12px 15px;


  @include dropShadow();
  @include typ-default();

  &__heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include typ-default-bold();
    margin-bottom: 4px;

    svg {
      width: 16px;
      height: auto;
      margin-right: 10px;
    }
  }

  &__success {
    svg {
      fill: $text-green;
    }
  }

  &__warning {
    svg {
      fill: $text-red;
    }
  }
}

.alert-fade-in {
  display: block;
  animation: alert-fade-in 0.5s ease-in;
}

.alert-fade-out {
  display: block;
  animation: alert-fade-out 0.5s ease-out;
}

@keyframes alert-fade-in {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes alert-fade-out {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}
