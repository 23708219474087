.arcus-loading {
  svg {
    max-width: 50px;
    animation: rotate 2s linear infinite;
  }
}

.screen-loading {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: $white;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-top: -150px;
  }
}

.item-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.arcus-loading__text-loader {
  position: relative;
  width: 80%;
  height: 0.5rem;
  margin-top: 8px;
  overflow: hidden;
  border-radius: 1000px;
  background-color: #d0d8dd;

  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 6rem;
    top: 0;
    animation: load 1s cubic-bezier(0.2, 0.7, 0.3, 1) infinite;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 100%);
  }
}

@keyframes load {
  from {
    left: -100px;
  }
  to {
    left: 100%;
  }
}

.loading-fade-out {
  animation: fadeOut 1s;
  animation-fill-mode: forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
