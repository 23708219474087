label {
    @include typ-default-bold();
    color: $header;
    margin-bottom: 4px;
  }


  .arcus-Calendar-Label{
font-family: "Default Bold";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.15px;
    text-decoration: none;
    color: #414552;
    margin-bottom:12px;
  }

  .arcus-Calendar-SubLabel{
    font-family: "Default Bold";
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.15px;
        text-decoration: none;
        color: #414552;
        margin-bottom:12px;
      }

  .arcus-Calendar{
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
    min-width: 375px;
    width: 100%;
    background-color: #fff;
    border: #e0e6eb solid 1.5px;
    border-radius: 8px;
    min-height: 32px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.0705882353), 0px 2px 5px rgba(60, 66, 87, 0.031372549);
    padding: 0px 10px;
    font-family: "Normal";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
  
.react-datepicker {
  font-family: RobotoMedium;
  font-size: 14px !important;
  color: #c7c7c7 !important;

  &__header {
    background-color: #c00d0d !important;
    padding-top: 12px !important;
    border: none !important;
  }

  &__day-name {
    color: #c7c7c7 !important;
    font-size: 14px !important;
  }

  &__current-month{
    color:white!important;
  }

  &__day {
    color: black !important;
    font-size: 13px !important;

    &--keyboard-selected,
    &--selected {
      border-radius: 25px !important;
      background: #c00d0d !important;
      color: $white !important;
    }
    &--disabled {
      color: #c7c7c7 !important;
    }
  }

  
}

.react-datepicker__calendar-icon {
  width: 1.5em!important;
  height: 1.5em!important;
  vertical-align: -0.125em;
  margin-top:6px;
}