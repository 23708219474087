// ----------------
// Colours
// ----------------
.colour-primary {
  color: $primary;
}

.colour-default {
  color: $text-default;
}

.colour-secondary {
  color: $text-secondary;
}

.colour-orange {
  color: $text-orange;
}

.colour-header {
  color: $header;
}

.colour-white {
  &__background-white{
    background-color: white;
  }
  &__20-OP{
    opacity: 0.8;
  }
}

.no-border{
  border:none!important
}




// ----------------
// Margins
// ----------------
// Margin - bottom
.margin-bottom-small { margin-bottom: 8px; }
.margin-bottom-medium { margin-bottom: 16px; }
.margin-bottom { margin-bottom: 32px; }

// Margin - right
.margin-right-small { margin-right: 8px; }
.margin-right-medium{ margin-right: 16px; }
.margin-right{ margin-right: 32px; }

// Marign - left
.margin-left-small { margin-left: 8px; }
.margin-left-medium{ margin-left: 16px; }
.margin-left{ margin-left: 32px; }

// Margin - top
.margin-top-small { margin-top: 8px; }
.margin-top-medium{ margin-top: 16px; }
.margin-top{ margin-top: 32px; }

.padding-medium{
  padding: 30px;
}

.padding-left-medium{
  padding-left: 32px!important;
}

.flex-wrap{
  flex-wrap:wrap;
}

.align-center{
  display:flex;
  justify-content: center;
  align-items: center;
}

.align-center-col{
  flex-direction: column;
}

.text-center{
  text-align: center;
}

// ----------------
// Alignments
// ----------------

// Alignment
.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.content-end {
  display: flex;
  justify-content: flex-end;
}

.content-flex {
  display: flex;
  align-items: center;
}

.content-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-even {

  justify-content: space-evenly;

}

.content-wrap {
  flex-wrap: wrap;
}
.flex-gap{
  gap: 16px;
}


.self-end{
  align-self:flex-end
}


.content-start{
  justify-content: flex-start!important;
}

.content-flex-row{
  flex-direction: row;
}

// ----------------
// Display
// ----------------
.content-hidden {
  display: none;
}


// ----------------
// Widths
// ----------------
.w-100{
  width: 100%;
}

.h-100{
  height: 100%;
}

.w-50{
  margin-top:20px;
  margin-left:50px;

}

.w-10{
  width:100px
}


// ----------------
// Lists
// ----------------
.list-style-none{
  list-style: none;
}

// ----------------
// Text
// ----------------
.no-text-decoration{
  text-decoration: none;
}

.scrollPage{
  overflow-x:auto;
  overflow-y:auto;
  width:100%;
  height: 100%;
}
//positioning
.absolute{
  position:absolute;
  &__top{
    &--50{
      top: 50%;
    }
  }
  &__left{
    &--50{
      left: 50%;
    }
  }
  &__transform{
    &--translateCenter{
      transform:translate(-50%, -50%);
    }
  }

  &__zindex1000{
    z-index: 1000;
  }


}
