.arcus-breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;

  &__item {
    gap: 6px;
    display: flex;
    align-items: center;
    *{

        color: $text-secondary;
        fill: $text-secondary;
    }
  }

  .active *{
    color: $primary;
  }
}
