.arcus-checkbox {
  display: flex;
  align-items: center;
  
  input {
    appearance: none;
    background-color: $white;
    margin: 0;
  }

  &__checkbox {
    border: $border solid 1.5px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    @include dropShadow();
    margin-right: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    .arcus-icon {
      display: none;
    }

    &--checked {
      background-color: $input-check;
      .arcus-icon {
        display: flex;
        svg {
          fill: $white;
        }
      }
    }
  }

  label {
    @include typ-default-bold;
    color: $text-default;
  }

  // Styles 
  &--fixed-width{
    min-width: 268px;
  }
}
