.arcus-pageLayout {



  .arcus-breadcrumbs {
    margin: 20px 0;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &--title {
      display: flex;
      align-items: center;
      gap: 16px;

      svg {
        width: 36px;
        height: 36px;
      }
      h2 {
        @include typ-h2();
        color: $header;
      }
    }

    &--buttonGroup {
      width: 100%;
      margin-top: 12px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 16px;

      @media (min-width: 576px) {
        width: fit-content;
        margin-top: auto;
      }
    }

    &--dataAccuracy {
      margin-left: 32px;
    }
  }

  &__divider {
    margin-top: 20px;
    height: 1px;
    background-color: $border;
    margin-bottom: 24px;
  }
}
