.arcus-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;

  &__column {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    &--fixed {
      max-width: 420px;
      width: 100%;
    }
    &--alignCenter{
      align-items:center
    }
    &--width80{
      width:60%
    }
  }
}
