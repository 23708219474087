.arcus-infoItem {
  &__icon {
  }

  &__title {
    @include typ-default-bold();
  }

  &__description {
    @include typ-robot-text();
  }

  // Margin
    &--margin {
        margin-bottom: 24px;
    }
}
