.arcus-filters {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  width: 0%;
  background-color: $white;
  height: 100vh;
  z-index: 999999;
  padding: 25px;
  @include filterShadow();

  &--fade-in {
    animation: fade-in 0.5s ease-in-out forwards;
  }

  &--fade-out {
    animation: fade-out 0.5s ease-in-out forwards;
  }

  &__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      @include typ-h3();
      color: $header;
    }
  }
}

@keyframes fade-in {
  0% {
    visibility: hidden;
    width: 0%;
  }
  100% {
    visibility: visible;
    width: 450px;
  }
}

@keyframes fade-out {
  0% {
    visibility: visible;
    width: 450px;
  }
  100% {
    visibility: hidden;
    width: 0%;
  }
}
