.arcus-radio-buttons {
  display: flex;
  align-items: center;
  gap: 16px 32px;
  flex-wrap: wrap;

  &__radio-button {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 4px;

    &--custom-radio {
      border: $border solid 1.5px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      @include dropShadow();

      display: flex;
      align-items: center;
      justify-content: center;

      &--inner {
        display: none;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $white;
      }
    }

    input {
      //Hides the default radio button
      appearance: none;
      background-color: $white;
      margin: 0;
    }

    label {
      @include typ-default-bold();
      color: $header;
    }
  }

  &--disabled *{
    opacity: 0.75;
    cursor: not-allowed !important;
  }

  .custom-radio-checked {
    background-color: $input-check;
    border: $input-check solid 1.5px;

    // Show the inner circle
    .arcus-radio-buttons__radio-button--custom-radio--inner {
      display: block;
    }
  }
}
