.arcus-start-connection-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
  
    h1 {
      @include typ-h1();
      margin-top: 32px;
      color: $primary;
    }
  
    p {
      @include typ-default();
      margin-top: 16px;
  
    }
  }
  