@mixin navbar-dropshadow {
  box-shadow: 0 0 4px #091e4213, 2px 2px 4px #091e4204;
}

@mixin dropShadow {
  box-shadow: 0px 1px 1px #00000012, 0px 2px 5px #3c425708;
}

@mixin filterShadow {
  box-shadow: -2px 1px 6px #00000015;
}
