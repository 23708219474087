* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#arcus-body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  // overflow-y:hidden;
}

.no-scroll {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
