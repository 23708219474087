.arcus-navbar {
  padding: 0 25px;
  border-bottom: $border solid 1px;
  @include navbar-dropshadow;
  height: 56px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &__resp {
    display: flex;

    @media (min-width: 768px) {
      display: none;
    }
  }

  &__inner-wrapper {
    display: flex;
    align-items: center;
    gap: 28px;
    width: 100%;

    &--client-select{
      z-index: 99999;
    }
  }

  // Logo
  &__logo {
    img {
      height: 20px;
    }
  }

  // NavItems
  &__items {
    display: none;
    align-items: center;

    @media (min-width: 768px) {
      display: flex;
    }
  }
  &__item {
    margin-right: 18px;
  }
}
