.arcus-dropdown {
  position: relative;
  z-index: 999;
  // Button
  &__button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  // Profile Content
  &__profile {
    display: flex;
    justify-content: center;
    align-items: center;

    .profile-image {
      border-radius: 50%;
      border: $border solid 1px;
      object-fit: contain;
      width: 30px;
      height: 30px;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
        object-position: center center;
      }
    }

    .profile-content {
      @media (min-width: 768px) {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      }
      display: none;


      p,
      small {
        white-space: nowrap;
      }

      p {
        @include typ-default-bold();
        color: $primary;
      }

      small {
        margin-top: -2px;
        color: $text-default;
        @include typ-small();
      }
    }
  }

  // Dropdown Content
  &__content {
    z-index: 9999;
    left: calc(100% - 150px);
    position: absolute;
    margin-top: 8px;
    background-color: $white;
    border: $border solid 1px;
    border-radius: 4px;
    @include dropShadow();
    min-width: 150px;

    display: none;
    align-items: flex-start;
    flex-direction: column;

    &__logout {
      border: none;
      outline: none;
      cursor: pointer;
      background-color: $background-red;
      span {
        color: $text-red !important;
      }
      svg {
        fill: $text-red !important;
      }
    }
    &__item {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      width: 100%;

      &:hover {
        background-color: $disabled;
      }

      span {
        @include typ-default();
        color: $text-default;
      }
      svg {
        width: 12px;
        height: 12px;
        fill: $text-default;
        margin-right: 8px;
      }
    }
  }

  &__show {
    display: flex;
  }
}
