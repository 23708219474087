.arcus-flex {
  display: flex;

  &__align {
    &--start {
      align-items: flex-start;
    }
    &--center {
      align-items: center;
    }
    &--end {
      align-items: flex-end;
    }
  }

  &__justify {
    &--start {
      justify-content: flex-start;
    }
    &--center {
      justify-content: center;
    }
    &--end {
      justify-content: flex-end;
    }
    &--between {
      justify-content: space-between;
    }
    &--around {
      justify-content: space-around;
    }
  }

  &__wrap {
    &--wrap {
      flex-wrap: wrap;
    }
    &--noWrap {
      flex-wrap: nowrap;
    }
  }

  &__direction {
    &--row {
      flex-direction: row;
    }
    &--rowReverse {
      flex-direction: row-reverse;
    }
    &--column {
      flex-direction: column;
    }
    &--columnReverse {
      flex-direction: column-reverse;
    }
  }

  &__gap {
    &--0 {
      gap: 0;
    }
    &--4 {
      gap: 4px;
    }
    &--8 {
      gap: 8px;
    }
    &--12 {
      gap: 12px;
    }
    &--16 {
      gap: 16px;
    }
    &--20 {
      gap: 20px;
    }
    &--24 {
      gap: 24px;
    }
    &--32 {
      gap: 32px;
    }
    &--40 {
      gap: 40px;
    }
    &--48 {
      gap: 48px;
    }
    &--56 {
      gap: 56px;
    }
    &--64 {
      gap: 64px;
    }
  }
}
