.arcus-form-page {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;

  &__showcase {
    display: none;
    background-color: $primary;
    overflow: hidden;
    width: 50%;
    max-width: 50%;
    min-width: 50%;
    height: 100%;
    position: relative;
    align-items: center;
    justify-content: center;

    .showcase-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }

    .showcase-content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h1 {
        @include typ-h1;
        color: $white;
        margin-bottom: 18px;
      }
      svg {
        max-width: 200px;
        g {
          fill: $white !important;
        }
      }
    }

    // Display showcase when screen is bigger than 768px
    @media (min-width: 768px) {
      display: flex;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    form {
      max-width: 350px;
    }

    .hide-content {
      animation: hideContent 0.5s ease-in-out forwards;
    }
    .success-message {
      margin-top: 10px;
      opacity: 0;
      @include typ-default-bold();

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        span {
          color: $text-green;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            margin-right: 8px;
            width: 12px;
            height: 12px;
            fill: $text-green;
          }
        }
      }
    
    }

    .show-content {
      animation: showContent 1s ease-in-out forwards;
    }

    // Display form when screen is bigger than 768px
    @media (min-width: 768px) {
      width: 50%;
    }
  }
}

@keyframes hideContent {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes showContent {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
  }
}
