// Main colours
$primary: #c00d0d;
$border: #e0e6eb;
$header: #414552;
$white: #fff;
$disabled: #d5dbe1;

// Border Colours
$border-red: #ffccdf;
$border-blue: #a2e5ef;
$border-green: #a6eb84;
$border-orange: #fcd579;
$border-grey: #d5dbe1;

// Background Colours
$background-dark-red: #df1b41;
$background-red: #ffe7f2;
$background-orange: #f6e6b9;
$background-green: #d7f7c2;
$background-blue: #cff5f6;
$background-grey: #ebeef1;
$background-card: #eff3f7;

// Text & Icons
$text-default: #30313d;
$text-red: #b3093c;
$text-orange: #a82c00;
$text-secondary: #697386;
$text-blue: #0055bc;
$text-green: #006908;

// Input
$input-check: #0570de;
