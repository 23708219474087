.arcus-icon {
  position: relative;
  display: flex;
  align-items: center;

  &--clickable {
    cursor: pointer;
  }

  &__description {
    position: absolute;
    top: 0;
    background-color: $background-grey;
    padding: 6px;
    border-radius: 4px;
    border: $border solid 1px;
    min-width: 150px;
    width: 100%;
    max-width: 250px;
    margin-left: 25px;
    color: $text-default;

    &--hide {
      display: none;
    }
  }

  // Colours
  &__colour {
    &--blue svg {
      fill: $text-blue !important;
    }

    &--green svg {
      fill: $text-green !important;
    }

    &--red svg {
      fill: $text-red !important;
    }

    &--orange svg {
      fill: $text-orange !important;
    }

    &--grey svg {
      fill: $text-secondary !important;
    }
  }

  // Sizes
  &__size {
    &--12 {
      width: 12px;
      height: 12px;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    &--16 {
      width: 16px !important;
      height: 16px !important;

      svg {
        width: 16px !important;
        height: 16px !important;
      }
    }
    &--20 {
      width: 20px !important;
      height: 20px !important;

      svg {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }
}
