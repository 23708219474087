.arcus-button {
  position: relative;
  display: flex;
  width: fit-content;
  border-width: 1.5px;
  border-style: solid;
  padding: 4px 8px;
  border-radius: 4px;
  @include dropShadow();
  @include typ-button();
  outline: none !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: $white;
  border-color: $border;
  color: $text-default;
  text-decoration: none;

  svg {
    width: 12px;
    height: 12px;
    fill: $text-default;
  }

  // Icon
  &__icon {
    &--start {
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--end {
      margin-left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  // Types
  &__icon-only {
    width: 30px !important;
    height: 30px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 12px;
      height: 12px;
      fill: $text-default;
    }
  }

  &__text {
    background-color: transparent;
    border-color: transparent;
    color: $text-default;
    padding: 0;
    box-shadow: none;

    &:hover {
      opacity: 0.75;
    }
  }

  &__external-link {
    display: flex;
    align-items: center;
    @include typ-button();
    color: $text-secondary;
    transition: all 0.2s ease-in-out;

    .arcus-icon svg {
      margin-left: 4px;
      width: 12px;
      height: 12px;
      fill: $text-secondary;
    }

    &:hover {
      opacity: 0.75;
    }
  }

  // Effects
  &:hover {
    opacity: 0.75;
  }

  // Scale
  &__default {
    width: fit-content;
  }

  &__full-width {
    display: block !important;
    width: 100%;
    .content-flex {
      justify-content: center;
    }
  }

  // Colours
  &__colour--primary {
    background-color: $primary;
    border-color: $primary;
    color: $white;

    .arcus-button__loading svg path {
      stroke: url(#white-spinner);
    }
  }

  &__colour--red {
    background-color: $background-dark-red;
    border-color: $background-dark-red;
    color: $white;

    .arcus-button__loading svg path {
      stroke: url(#white-spinner);
    }
  }

  &__colour--default {
    background-color: $white;
    border-color: $border;
    color: $text-default;
  }

  // Loading
  &__loading {
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
      fill: transparent;

      path {
        stroke: url(#red-spinner);
      }
      animation: rotate 1s linear infinite;
    }
  }

  .hide {
    visibility: hidden;
  }
}

.square-icon-button {
  padding: 8px !important;
}

.currentURL{
  color:#c00d0d!important;
}
