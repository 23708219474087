.arcus-heading {
  margin-bottom: 32px;
  width: 100%;

  &__link {
    text-decoration: none;
    width: 100%;
  }

  &__inner-wrapper {
    border-bottom: $border solid 1.5px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &--no-margin{
    border-bottom:0px!important;
    margin-bottom:0px!important;
  }

  &__title {
    width: 100%;
    @include typ-h2();
    color: $header;
    padding-bottom: 8px;

    &--small {
      @include typ-default-bold();
      color: $header;
      padding-bottom: 8px;
    }
  }
  button {
    height: fit-content;
  }

  &__subContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--subheading {
      margin-top: 8px;
      h2 {
        @include typ-h3();
        color: $header;
        margin-bottom: 4px;
      }
      p {
        @include typ-default();
        color: $text-secondary;
      }
    }
  }
  &--small-margin {
    margin-bottom: 8px;
  }
}
