.arcus-select {
  position: relative;

  label {
    @include typ-default-bold();
    color: $header;
    margin-bottom: 4px;
  }
  // Showcase selected Item
  &__select {
    min-width: 335px;
    width: 100%;
    background-color: $white;
    border: $border solid 1.5px;
    border-radius: 8px;
    min-height: 32px;
    @include dropShadow();
    padding: 0px 10px;
    @include typ-default();
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__icon {
      svg {
        width: 8px;
        fill: $text-default();
      }
    }
  }

  // Dropdown for select
  &__content {
    display: none;
    position: absolute;
    width: inherit;
    margin-top: 12px;
    background-color: $white;
    border: $border solid 1.5px;
    border-radius: 4px;
    width: 100%;
    @include dropShadow();
    max-height: 250px;
    overflow-y: scroll;
    z-index:999;
    
    &--show {
      display: block;
    }

    &--search {
        width: 100%;
        padding: 5px;
      margin-bottom: 8px;

      input {
        width: inherit;
        border: $border solid 1.5px;
        background-color: $white;
        padding: 2px 5px;
        @include typ-default();
        outline: none;
      }
    }

    &__list{

        &--item{
            background-color: $white;
            padding: 5px 5px;
            @include typ-default-bold();
            color: $text-default;
            transition: all 0.5s ease-in-out;
            cursor: pointer;

            &:hover{
                background-color: $background-grey;
            }
        }
    }
  }

  //Actions
  &--disabled *{
    opacity: 0.75;
    cursor: not-allowed !important;
  }

  // Styles
  &--short {
    max-width: 300px;
  }

  &--medium {
    max-width: 450px;
  }
}
