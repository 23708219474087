.arcus-barchart {
  &--small {
    max-width: 480px;
  }

  &--medium {
    max-width: 680px;
  }

  &--large {
    max-width: 780px;
  }

  &--xl {
    max-width: 3000px;
  }

  &--fill-width {
    width: fit-content;
  }
}
