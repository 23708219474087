.arcus-input {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  &__label {
    @include typ-default-bold();
    margin-bottom: 4px;
  }

  &__inner-wrapper {
    width: inherit;
  }

  &__input {
    min-width: 335px;
    width: 100%;
    background-color: $white;
    border: $border solid 1.5px;
    border-radius: 8px;
    min-height: 32px;
    @include dropShadow();
    padding: 0px 5px;
    @include typ-default();

    &:focus {
      outline: none;
      border: $disabled solid 1.5px;
      box-shadow: none;
    }
  }

  &__right-icon,
  &__left-icon {
    position: absolute;
    right: 20px;
    top: 32px;

    padding: 0;
    margin: 0;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    button {
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;
    }

    &:hover {
      svg {
        fill: $primary;
      }
    }

    svg {
      width: 16px;
      height: 16px;
      fill: $text-default;
    }
  }

  &__left-icon {
    left: 10px;
  }

  .input-link{
    margin-top: 8px;

    &__right{
      margin-left: auto;
    }
  }

  // Styles
  &--short{
    max-width: 300px;
  }

  &--medium{
    max-width: 450px;
  }
}


