.arcus-tabs {
  // Headers
  &__headers {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    justify-content: space-between;

    &--tab-items{
      display: flex;
      align-items: center;
    }
  }

  &__header {
    padding: 0.5rem 1rem;
    @include typ-default-bold();
    border-bottom: $border solid 2px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $primary;
    }

    &--active {
      border-color: $primary;
      color: $primary;
    }
  }

  // Content
  &__content{
    padding-top: 12px;
  }
}
