.arcus-league-table {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  margin-bottom: 1rem;
  background-color: #fff;
  position: relative; /* Add position relative to the container */

  .arcus-league-table__table {
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: auto;
    margin-bottom: 0;  
    border-spacing: 0;
    border-collapse:separate;

    thead {
      tr {
        th {
          padding: 4px 10px;
          vertical-align: bottom;
          border-bottom: 1.5px solid $primary;
          text-align: left;
          position: sticky;
          top: 0;
          background-color: #fff;
          z-index: 1;
          &:first-child {
            padding-left: 1.25rem;
          }
          &:last-child {
            padding-right: 1.25rem;
          }
          @include typ-default-bold();
          color: $header;
        }
      }
    }
    

    tbody {
      tr {
        *{@include typ-table()}
                td {
                  
          a {
            text-decoration: none;
            color: $text-default;
            @include typ-table();
          }
          padding: 10px;
          vertical-align: top;
          border-bottom: 1px solid $border;
          &:first-child {
            padding-left: 1.25rem;
          }
          &:last-child {
            padding-right: 1.25rem;
          }
        }
        &:hover {
          background-color: rgba(0, 0, 0, 0.12)!important;
        }
      }
    }

    tfoot {
      tr {
        border-top: 1.5px solid $primary;

        td {
          padding: 4px 10px;
          vertical-align: bottom;
          border-top: 1.5px solid $primary;
          text-align: left;
          background-color: #fff;
          z-index: 1;
          &:first-child {
            padding-left: 1.25rem;
          }
          &:last-child {
            padding-right: 1.25rem;
          }
          @include typ-default-bold();
          color: $header;
        }
      }
    }

    .arcus_tdColour_green {
      background: rgba(103, 235, 107, 0.5);
    }

    .arcus_tdColour_red {
      background: rgba(234, 102, 102, 0.5);
    }

    .arcus_tdColour_amber {
      background: rgba(235, 186, 103, 0.5);
    }

    .linkCell {
      color: rgba(68, 170, 253, 0.98);
      text-decoration: underline;
    }    
  }
}


.arcus-league-table-Stores {
  max-height: 65vh; /* Adjust the max height as needed */
  overflow-y:auto;
  overflow-x:auto;
}

.arcus-league-table-Assets {
  max-height: 48vh;
  overflow-y:auto;
  overflow-x:auto;

}

.arcus-league-table-WorkOrders {
  max-height: 60vh;
  max-width: 70vw;

  overflow-y:auto;
  overflow-x:auto;

}



.arcus-league-table__table--rows tr:nth-child(even) {
  background-color: #f2f2f2!important; /* Set the background color for even rows */
}

.arcus-league-table__table--rows tr:nth-child(odd) {
  background-color: #ffffff!important; /* Set the background color for odd rows */
}

.arcus-league-table__table--footer {
  position: sticky; /* Use sticky position for the footer */
  bottom: 0;
  background-color: white;
  z-index: 2; /* Make sure the footer appears above the table */
}

/* Adjust the height of your footer if needed */
.arcus-league-table__table--footer tr {
  height: 40px;
}