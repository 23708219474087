.arcus-table-container {
  position: relative;
  overflow-x: auto;

  // Make horizontal scroll bar height smaller
  &::-webkit-scrollbar {
    height: 6px;
  }
}

.arcus-table {
  margin-top: 16px;
  border-collapse: collapse;
  overflow-x: auto;
  width: 100%;
  display: table;

  &__headers {
    width: 100%;
    &--row {
      border-collapse: collapse;
      width: 100%;
    }
    &--row-item {
      overflow: hidden;
      text-overflow: ellipsis;
      color: $header;
      padding: 4px 10px;
      border-bottom: $primary solid 1.5px;
      min-height: 35px;
      vertical-align: bottom;
      text-align: left;
      @include typ-default-bold;
    }
  }

  &__body {
    &--row {
      color: $text-default;
      padding: 8px 10px;
      min-height: 35px;
      border-bottom: $border solid 1px;
      width: 100%;
      vertical-align: top;

      .highlight-row {
        background-color: $primary;
        color: $white;
      }

      &--expanding-content {
        transition: all 0.4s ease-in-out;
        display: none;
        opacity: 0;
        height: 0;
        &--hide {
          animation: fade-out 0.4s ease-in-out forwards;
        }
        &--show {
          display: table-row;
          animation: fade-in 0.4s ease-in-out forwards;
        }
      }
    }

    &--row-item {
      color: $text-default;
      padding: 8px 10px;
      min-height: 35px;
      height: 100%;
      min-width: 150px;
      border-bottom: $border solid 1px;
      vertical-align: middle;
      position: relative;
      @include typ-table();

      a:not(.arcus-button) {
        display: flex;
        align-items: center;
        justify-content: start;
        box-sizing: border-box;
        height: 100% !important;
        min-height: inherit;
        text-decoration: none;
        color: $text-default;
        padding: 8px 10px;
        @include typ-table();
      }

      &--expandable {
        cursor: pointer;
        i {
          transition: all 0.4s ease-in-out;
        }
      }
      &--link {
        cursor: pointer;
        padding: 0 !important;
      }

      &--highlight {
        &--red {
          background-color: $background-red;
        }
      }
    }

    &--no-data {
      text-align: center;
      padding: 16px;
      color: $text-default;
      @include typ-default();
    }
  }

  &__data {
    &--nowrap {
      th,
      td {
        text-wrap: nowrap;
      }
    }
  }
}

// Animation
@keyframes fade-in {
  0% {
    opacity: 0;
    height: 0;
    display: table-row;
  }

  100% {
    opacity: 1;
    height: auto;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    height: auto;
  }

  100% {
    opacity: 0;
    height: 0;
    display: none;
  }
}
