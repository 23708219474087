.arcus-forbidden-notfound{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    text-align: center;
    h1{
        @include typ-title();
        color: $primary;
        margin-bottom: 32px;
    }

    p{
        @include typ-default-bold();
        color: $text-default;
        margin-bottom: 18px;
    }

}