.arcus-switch {
  &__label {
    @include typ-default-bold();
    margin-bottom: 8px;
    display: block;
  }

  &__wrapper {
    input {
      display: none;
    }
    display: flex;
    align-items: center;
    p{
      margin-left: 8px;
    }
  }

  &__slider {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    border-radius: 20px;
    background-color: #ccc;
    transition: background-color 0.2s ease;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #fff;
      transition: left 0.2s ease;
    }

    &--checked {
      background-color: $primary;
      &::after {
        left: 22px;
      }
    }
  }
}
