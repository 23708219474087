.arcus-gauge {
  width: 100%;
  max-width: 175px;
  position: relative;

  &__title {
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translateX(-50%);
    @include typ-default-bold();
    color: $header;
    width: 100%;
    text-align: center;
  }

  &__value {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translateX(-50%);
    z-index:-1;
    width: 50%;
    text-align: center;
    @include typ-default();
    color: $text-default;

    > * {
      width: 100%;
    }

  }
}
